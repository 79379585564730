/**
 * Return a Boolean reducer
 *
 * @param state
 * @param action
 * @returns object
 */
export const booleanReducer = (state, action) => {
  // If we are toggling without passing anything then we should flip the state
  // Else lets use what they give us
  const { payload } = action;
  return {
    ...state,
    [`${payload.scope}`]: (payload.boolValue == null) ? !state[`${payload.scope}`] : payload.boolValue,
  };
};

/**
 * Return a Fetching Action reducer
 *
 * @param state
 * @param action
 * @returns object
 */
export const fetchingReducer = (state, action) => {
  const { payload } = action;
  return booleanReducer(state, {
    payload: {
      scope: `${payload.scope}_isFetching`,
      boolValue: payload.fetching,
    },
  });
};

/**
 * Return a Loading Action reducer
 *
 * @param state
 * @param action
 * @returns object
 */
export const loadingReducer = (state, action) => {
  const { payload } = action;
  return booleanReducer(state, {
    payload: {
      scope: `${payload.scope}_isLoading`,
      boolValue: payload.loading,
    },
  });
};

/**
 * Return a Request Action reducer
 *
 * @param state
 * @param action
 * @returns object
 */
export const requestReducer = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    [`${payload.scope}`]: payload.data,
  };
};
