import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import './StoresList.scss';
import classNames from 'classnames';

function StoresList({ stores, onClickStore }) {
  const storeDetails = [];

  Object.entries(stores).forEach(([index, store]) => {
    storeDetails.push(
      <FormGroup
        key={store.uuid}
        className="list-view-item row mx-0"
        onClick={() => onClickStore(index)}
      >
        <div className="col-3 col-sm-2 text-center">
          <div className="d-block">
            <i className="fas fa-car" />
          </div>
          <span>{store.distance || 0} mi.</span>
        </div>
        <div className="description col-8 col-sm-9">
          <span className="d-block text-truncate">
            <strong>{store.display_name || 'Store Name'}</strong><br />
            {store.address || 'street'}<br />
            {store.city || 'City'}, {store.state || 'ST'} {store.zip || '0000'}
          </span>
        </div>
        <div className="col-1 ms-auto text-right">
          <div className="icon">
            <i className="fas fa-caret-right" />
          </div>
        </div>
      </FormGroup>,
    );
  });

  return (
    <div className="StoresList">
      {storeDetails}
    </div>
  );
}

StoresList.propTypes = {
  onClickStore: PropTypes.func,
  stores: PropTypes.arrayOf(PropTypes.object),
};

StoresList.defaultProps = {
  onClickStore: null,
  stores: [],
};

export default StoresList;
