import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import PrivacyPolicyModal from '../../components/PrivacyPolicyModal/PrivacyPolicyModal';
import CopyrightNotice from '../../components/CopyrightNotice/CopyrightNotice';
import { AuthDataOperations, AuthDataSelectors } from '../../state/ducks/AuthData';

import './Home.scss';

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isMember = localStorage.getItem('session') === 'member';
  const isReady = useSelector((state) => AuthDataSelectors.isReady(state));
  const session = useSelector((state) => AuthDataSelectors.userData(state));

  /**
   * Initiates the user authorization process.
   *
   */
  const auth = () => dispatch(AuthDataOperations.authStart()).then(() => navigate('/stores'));

  /**
   * The hook tracks the user's status in LocalStorage. If the user
   * was previously logged in as a member, verification authorization
   * is initiated, as a result of which either the user's session data
   * is updated, or the corresponding status is removed.
   *
   */
  useEffect(() => {
    if (isMember) {
      dispatch(AuthDataOperations.authStart(true));
    }
  }, [dispatch, isMember, location]);

  /**
   * The hook tracks the initialization of the user's session, redirecting
   * it to the stores page when a session is detected. Thus, the redirection
   * will be performed either before the end of the session, or before the
   * user logout.
   *
   */
  useEffect(() => {
    if (session) {
      navigate('/stores');
    }
  }, [session, navigate]);

  return (
    <div className="Home">
      <header>
        <div className="logo-wrapper">
          <img src="/img/app-logo.png" alt="Prime Time Nutrition" />
        </div>
      </header>

      <PrivacyPolicyModal />

      <div className="safeAreaWrapper">
        {isReady ? (
          <div className="content-wrapper">
            <Button color="primary" size="lg" block onClick={auth}>
              <i className="fas fa-sign-in-alt" /> Login
            </Button>
            <Button color="secondary" size="lg" block onClick={auth}>
              <i className="fas fa-user-plus" /> Create Account
            </Button>
            <p className="text-center">Locate the nearest Prime Time Nutrition or Quickeroo store to start your order.</p>
          </div>
        ) : (
          <>
            <span className="loadingSpinner"><i className="fas fa-spinner fa-spin" /></span>
            <p className="text-center fa-2x">Please, wait.</p>
          </>
        )}
      </div>

      <CopyrightNotice />
    </div>
  );
}

export default Home;
