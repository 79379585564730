import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import './StoreSearchForm.scss';

function StoreSearchForm(props) {
  const { bindSearchQuery, bindRange, onSubmit } = props;

  return (
    <form onSubmit={onSubmit} className="StoreSearchForm">
      <FormGroup className="search-wrapper">
        <Row>
          <Col xs="7">
            <Input
              type="text"
              placeholder="Zip Code"
              {...bindSearchQuery}
            />
          </Col>
          <Col xs="5">
            <Input
              type="select"
              title="Range"
              {...bindRange}
            >
              <option value="25">25 Miles</option>
              <option value="100">100 Miles</option>
              <option value="500">500 Miles</option>
              <option value="1000">1000 Miles</option>
            </Input>
          </Col>
        </Row>
      </FormGroup>
      <Button block color="primary" type="submit">List</Button>
    </form>
  );
}

StoreSearchForm.propTypes = {
  bindSearchQuery: PropTypes.any,
  bindRange: PropTypes.any,
  onSubmit: PropTypes.func,
};

StoreSearchForm.defaultProps = {
  bindRange: null,
  bindSearchQuery: null,
  onSubmit: null,
};

export default StoreSearchForm;
