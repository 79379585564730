import * as types from './types';

import { createReducer, reducers } from '../../utils';

/* State shape
{
  lastQuery: {
    lat: null,
    lng: null,
    range: null,
    searchQuery: null,
  },
  range: null,
  selectedStore: null,
  selectedStoreTimesByDoW: {
    M: {
      end: '1500',
      endHour: 15,
      endMinute: 0,
      start: '1000',
      startHour: 10,
      startMinute: 0,
    },
    T: {
      end: '1500',
      endHour: 15,
      endMinute: 0,
      start: '1000',
      startHour: 10,
      startMinute: 0,
    },
    ...
  },
  stores: [
    {
      "uuid": "1",
      "vendor_id": "320377",
      "store_no": "001",
      "store_number": "001",
      "name": "Valley",
      "description": "Mon - Fri: 8-6<br \/>Sat: 9-4<br \/>Sun: 9-4<br \/>",
      "multiplier": ".00",
      "prod_group": "",
      "ipaddr": "",
      "transfers_from": "",
      "delivery_fee": "0",
      "price_group": "",
      "prod_lock": "",
      "add_group": "",
      "add_group2": "",
      "address": "3560 Santa Anita Ave Suite J",
      "city": "El Monte",
      "state": "CA",
      "zip": "91731",
      "phone_number": "(626) 442-1125",
      "email_address": "001@nfiptn.com, support@ckstech.com",
      "dm_group": "",
      "display_name": "Prime Time Nutrition #001",
      "comp_store": "0",
      "vwix_id": "",
      "dp_group": "",
      "lat": "34.074072",
      "lng": "-118.040627",
      "allows_curbside_pick_up": "1",
      "active": "1",
      "start_pickup": "{\"SUN\": \"1000\", \"MON\": \"0900\",\"TUE\": \"0900\",\"WED\": \"0900\",\"THU\": \"0900\",\"FRI\": \"0900\",\"SAT\": \"1000\"}",
      "end_pickup": "{\"SUN\": \"1500\", \"MON\": \"1700\",\"TUE\": \"1700\",\"WED\": \"1700\",\"THU\": \"1700\",\"FRI\": \"1700\",\"SAT\": \"1500\"}"
    },
  ],
  stores_isFetching: false,
}
*/

const initialState = {
  lastQuery: {
    lat: null,
    lng: null,
    range: '25',
    searchQuery: '',
  },
  selectedStore: null,
  selectedStoreTimesByDoW: null,
  stores: [],
  stores_isFetching: false,
};

const analyticsInsightLabelsItemReducer = createReducer(initialState)({
  [types.RECEIVE_STORES_REQUEST]: (state, action) => reducers.requestReducer(state, action),
  [types.SET_STORES_FETCHING]: (state, action) => reducers.fetchingReducer(state, action),
  [types.SET_STORES_LOADING]: (state, action) => reducers.loadingReducer(state, action),
  [types.TOGGLE_STORES_BOOLEAN]: (state, action) => reducers.booleanReducer(state, action),
});

export default analyticsInsightLabelsItemReducer;
