import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
import { LoadScript } from '@react-google-maps/api';

import './App.scss';
import configureAppStore from './state/store';
// Pages
import CartSummary from './pages/CartSummary/CartSummary';
import Home from './pages/Home/Home';
import CdpCallback from './pages/CdpCallback/CdpCallback';
import ItemCategories from './pages/ItemCategories/ItemCategories';
import ItemDetails from './pages/ItemDetails/ItemDetails';
import OrderInfo from './pages/OrderInfo/OrderInfo';
import Stores from './pages/Stores/Stores';
import StoreDetails from './pages/StoreDetails/StoreDetails';
import ThankYou from './pages/ThankYou/ThankYou';

const store = configureAppStore();

function App() {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBGoZfhfixvXbA5_kqPQQa-JYnjk4aLIQk"
      version="3.exp"
    >
      <Provider store={store}>
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cdp-callback" element={<CdpCallback />} />
              <Route path="/cart-summary" element={<CartSummary />} />
              <Route path="/item-categories" element={<ItemCategories />} />
              <Route path="/item-details/:catId/:productId" element={<ItemDetails />} />
              <Route path="/order-info" element={<OrderInfo />} />
              <Route path="/stores" element={<Stores />} />
              <Route path="/store-details" element={<StoreDetails />} />
              <Route path="/thank-you" element={<ThankYou />} />
            </Routes>
          </div>
        </Router>
      </Provider>
    </LoadScript>
  );
}

export default App;
