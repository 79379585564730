import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { AuthDataOperations, AuthDataSelectors } from '../../state/ducks/AuthData';
import './ProfileArea.scss';

const MySwal = withReactContent(Swal);

function ProfileArea() {
  const dispatch = useDispatch();
  const session = useSelector((state) => AuthDataSelectors.userData(state));
  const isAuthReady = useSelector((state) => AuthDataSelectors.isReady(state));

  const [tooltipLogout, setTooltipLogout] = useState(null);
  const [tooltipName, setTooltipName] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [timer, setTimer] = useState(null);

  /**
   * Serves the user logging out, displaying a pop-up notification
   * to confirm the action, and, upon a satisfactory response, initiating
   * the deletion of access data (cookies and status in LocalStorage) and
   * redirection to the main page.
   *
   */
  const confirmLogout = (() => {
    MySwal.fire({
      title: 'Are you sure you want to log out?',
      text: 'Your cart will be emptied and you will be redirected to the home page.',
      width: '75%',
      showCancelButton: true,
      focusCancel: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.value === true) {
        MySwal.fire({
          title: 'Please, wait.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => dispatch(AuthDataOperations.authOut()).then(() => document.location.assign('/')),
        });
      }
    });
  });

  /**
   * The hook tracks the user's session, initiating verification authorization
   * if it is not present (for example, when updating the site in the browser).
   *
   */
  useEffect(() => {
    if ((session === null || Object.keys(session).length === 0) && isAuthReady) {
      dispatch(AuthDataOperations.authStart());
    }
  }, [dispatch, isAuthReady, session]);

  /**
   * The hook monitors the user's session, and if the user has not
   * given permission to refresh access, it generates and every second
   * updates a timer counting the expiration of the current session.
   *
   */
  useEffect(() => {
    if (session !== null && Object.keys(session).length > 0) {
      let left = 0;

      if (!timer && !session.refreshable) {
        left = (session.expires_in - Date.now() / 1000);

        const ticker = () => {
          if (left > 0) {
            const mins = String(Math.floor(left / 60));
            const secs = String(left - mins * 60).split('.')[0];

            setTimer(
              left > 0
                ? `${(mins.length === 2 ? mins : `0${mins}`)}:${(secs.length === 2 ? secs : `0${secs}`)}`
                : '--:--',
            );

            left -= 1;
            setTimeout(ticker, 1000);
          }
        };

        ticker();
      }

      return () => (left = 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  /**
   * The hook tracks the user's session, either refreshing the user's
   * current accesses (if he gave permission), or auto-logout the user
   * when the session expires (with displaying a notice to the user and
   * redirecting to the home page).
   *
   */
  useEffect(() => {
    if (session !== null && Object.keys(session).length > 0) {
      const secondsUntilExpiration = session.expires_in - Date.now();

      const deferred = session.refreshable
        ? setTimeout(() => {
          dispatch(AuthDataOperations.authStart(true));
        }, (secondsUntilExpiration - (30 * 60)) * 1000)
        : setTimeout(() => (
          MySwal.fire({
            title: 'Your Session Expired',
            text: 'You must login again, we are redirecting you to the homepage.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            willOpen: () => dispatch(AuthDataOperations.authOut()).then(() => setTimeout(() => document.location.assign('/'), 2000)),
          })), secondsUntilExpiration * 1000);

      return () => clearTimeout(deferred);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  /**
   * The hook tracks the user's session and, after initializing it,
   * forms the username displayed in the profile, shortening too
   * long names.
   *
   */
  useEffect(() => {
    if (!fullname && session) {
      const name = `${session.firstname} ${session.lastname}`;

      if (name.length < 32) {
        return setFullname(name);
      }

      setTooltipName(false);
      setFullname(`${session.firstname.split(' ')[0]} ${session.lastname.substr(0, 1)}.`);
    }
  }, [session, fullname]);

  if (!session) {
    return (
      <div className="profile">
        <span className="loadingSpinner text-white">
          <i className="fas fa-spinner fa-spin" />
        </span>
      </div>
    );
  }

  return (
    <div className="profile">
      {session?.firstname && (
        <>
          <span className="text-white w-100 d-inline-flex justify-content-between">
            <span id="fullname" className="d-none d-md-block">
              {fullname}
            </span>
            {tooltipName !== null && (
              <Tooltip placement="left" target="fullname" isOpen={tooltipName} toggle={() => setTooltipName(!tooltipName)}>
                {session.firstname} {session.lastname}
              </Tooltip>
            )}
            <button type="button" className="logoutButton" onClick={confirmLogout}>
              <i className="fas fa-sign-out-alt" id="logout" />
              <Tooltip placement="bottom" target="logout" isOpen={tooltipLogout} toggle={() => setTooltipLogout(!tooltipLogout)}>
                Logout
              </Tooltip>
            </button>
          </span>
          <br />

          {!session?.refreshable && (
            <span className="text-white w-100 d-inline-flex justify-content-between">
              Until the end of the session {timer && <span> {timer}</span>}
            </span>
          )}
        </>
      )}

    </div>
  );
}

export default ProfileArea;
