import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import './ItemCategory.scss';
import CartQty from '../CartQty/CartQty';
import Product from '../Product/Product';
import { WumeiDataSelectors } from '../../state/ducks/WumeiData';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { calculateBenefitCoverageForCategory, parseCommaFloat, sortByInCartThenSeq } from '../../utils';

function ItemCategory(props) {
  const { catId, description, imageUrl, isOpen, onProductClick, onToggleClick, products, uom } = props;
  const cartQtyByProduct = useSelector((state) => ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const items = [];

  // If there are no benefits for this category then we don't show it
  if (benefitsByCategory == null || Object.keys(benefitsByCategory).length === 0 || benefitsByCategory[`cat-${catId}`] == null) {
    return;
  }

  const benefitsAvailableForCat = Object.values(benefitsByCategory[`cat-${catId}`]).reduce((acc, benefit) => acc + benefit.available, 0);

  // Put our products in order based on sequence or ordered amount
  const productsInOrder = Object.entries(products).sort(sortByInCartThenSeq(cartQtyByProduct));
  const { catCartBftQty, catCartPrice, catCartQty, catBenefitsCovered, catOverBenefit } = calculateBenefitCoverageForCategory(productsInOrder, benefitsByCategory[`cat-${catId}`], cartQtyByProduct);

  productsInOrder.forEach(([_, item]) => {
    const { descript: itemDescription, fiseq, image_url: itemImageURL, prodkey, subid } = item;
    const benefitsAvailable = benefitsByCategory[`cat-${catId}`]?.[`sub-${subid}`]?.available || 0;

    // If there are no benefits or inventory for this product then we do not show it
    if (benefitsAvailable <= 0 || (fiseq != null && fiseq.length > 0 && parseInt(fiseq, 10) <= 0)) {
      return;
    }

    const itemPrice = parseCommaFloat(item.price);
    const cartQty = parseInt(cartQtyByProduct[prodkey]) || 0;
    const cartPrice = cartQty * itemPrice;

    items.push(
      <Product
        key={prodkey}
        benefitsAvailable={benefitsAvailable}
        cartPrice={cartPrice}
        cartQuantity={cartQty}
        catUOM={uom}
        className="item item-block item-md"
        description={itemDescription}
        imageUrl={itemImageURL || imageUrl}
        onProductClick={() => onProductClick(catId, prodkey)}
      />,
    );
  });

  return (
    <div role="link" className={classNames('ItemCategory', { 'd-none': items.length === 0 })}>
      <div className="divider" onClick={onToggleClick} aria-hidden="true">
        <div className="row align-items-center">
          <div className="col-9 col-sm-7 col-md-6 col-lg-7">
            <div className="d-flex">
              <div className="toggleIcon icon">
                <i className={classNames('fas', { 'fa-caret-down': !isOpen, 'fa-caret-up': isOpen })} />
              </div>
              <div className="description">
                <span className="d-block text-truncate">
                  {description}
                </span>
              </div>
            </div>
          </div>
          <CartQty
            benefitsAvailable={benefitsAvailableForCat}
            cartBftQty={catCartBftQty}
            cartQty={catCartQty}
            className="col-3 col-sm-5 col-md-6 col-lg-5 ms-auto text-right"
            covered={catBenefitsCovered}
            overPrice={catOverBenefit}
            price={catCartPrice}
            uom={uom}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="list sub-list">
          {items}
        </div>
      </Collapse>
    </div>
  );
}

ItemCategory.propTypes = {
  catId: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  onProductClick: PropTypes.func,
  onToggleClick: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.object),
  uom: PropTypes.string,
};

ItemCategory.defaultProps = {
  catId: null,
  description: null,
  imageUrl: null,
  isOpen: false,
  onProductClick: null,
  onToggleClick: null,
  products: [],
  uom: null,
};

export default ItemCategory;
