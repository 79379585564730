import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ItemCategoriesList.scss';
import ItemCategory from '../ItemCategory/ItemCategory';

function ItemCategoriesList({ categories, onProductClick }) {
  const itemCats = [];
  const [selectedCategory, setSelectedCategory] = useState(null);

  Object.entries(categories).forEach(([, group]) => {
    const { WICCAT, DESCRIPT, image_url, products, UOM } = group;

    itemCats.push(
      <ItemCategory
        key={WICCAT}
        catId={WICCAT}
        description={DESCRIPT}
        imageUrl={image_url}
        isOpen={(WICCAT === selectedCategory)}
        onProductClick={onProductClick}
        onToggleClick={() => setSelectedCategory(WICCAT)}
        products={products}
        uom={UOM}
      />,
    );
  });

  return (
    <div className="ItemCategoriesList">
      {itemCats}
    </div>
  );
}

ItemCategoriesList.propTypes = {
  onProductClick: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.object),
};

ItemCategoriesList.defaultProps = {
  onProductClick: null,
  categories: [],
};

export default ItemCategoriesList;
