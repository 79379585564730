import React from 'react';
import PropTypes from 'prop-types';
import './CartQty.scss';
import classNames from 'classnames';

function CartQty(props) {
  const { benefitsAvailable, cartBftQty, cartQty, className, covered, overPrice, price, uom } = props;
  const overLimit = cartBftQty - covered;
  const hasBenefits = benefitsAvailable > 0;
  const isOverLimit = overLimit > 0;

  return (
    <span className={classNames('CartQty', className)}>
      <span className={classNames('d-none', 'd-md-inline')}>Benefits: </span>
      <span className={classNames('d-none', 'd-md-inline', { 'text-success': hasBenefits, 'text-danger': !hasBenefits })}>{benefitsAvailable}</span>

      {cartQty > 0 && (
        <>
          <span className={classNames('d-none', 'd-md-inline')}> | </span>

          <span className={classNames('d-none', 'd-sm-inline')}>Total: </span>
          <span className={classNames({ 'text-primary': !isOverLimit, 'text-danger': isOverLimit })}>
            ${price.toFixed(2)}
          </span>

          {isOverLimit && (
            <>
              <span className={classNames('d-none', 'd-lg-inline')}> | Over limit: </span>
              <span className={classNames('d-none', 'd-lg-inline', 'text-danger')}>{overLimit} </span>
              {uom} - ${overPrice.toFixed(2)}
            </>
          )}
        </>
      )}
    </span>
  );
}

CartQty.propTypes = {
  benefitsAvailable: PropTypes.number,
  cartBftQty: PropTypes.number,
  cartQty: PropTypes.number,
  className: PropTypes.string,
  covered: PropTypes.number,
  overPrice: PropTypes.number,
  price: PropTypes.number,
  uom: PropTypes.string,
};

CartQty.defaultProps = {
  benefitsAvailable: 0,
  cartBftQty: 0,
  cartQty: 0,
  className: '',
  covered: 0,
  overPrice: 0,
  price: 0,
  uom: null,
};

export default CartQty;
