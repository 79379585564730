import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

// import monitorReducersEnhancer from './enhancers/monitorReducer';
// import loggerMiddleware from './middleware/logger';
import * as reducers from './ducks';

export default function configureAppStore(preloadedState) {
  const rootReducer = combineReducers(reducers);

  return configureStore({
    reducer: rootReducer,
    middleware: [
      // loggerMiddleware,
      ...getDefaultMiddleware()
    ],
    preloadedState,
    // enhancers: [monitorReducersEnhancer],
  });
}
