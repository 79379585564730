import * as types from './types';

import { createReducer, reducers } from '../../utils';

const initialState = {
  benefits: null,
  benefitsByCategory: null,
  wumei_isFetching: false,
};

const analyticsInsightLabelsItemReducer = createReducer(initialState)({
  [types.RECEIVE_WUMEI_REQUEST]: (state, action) => reducers.requestReducer(state, action),
  [types.SET_WUMEI_FETCHING]: (state, action) => reducers.fetchingReducer(state, action),
});

export default analyticsInsightLabelsItemReducer;
