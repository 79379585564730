import React, { useEffect, useMemo } from 'react';
import { Alert, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import './CartSummary.scss';
import { useNavigate } from 'react-router-dom';
import MainNavbar from '../../components/MainNavbar/MainNavbar';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import CartCategoriesList from '../../components/CartCategoriesList/CartCategoriesList';
import CancelButton from '../../components/CancelButton/CancelButton';
import { WumeiDataSelectors } from '../../state/ducks/WumeiData';
import { calculateBenefitCoverageForCategory } from '../../utils';
import { returnTotalCartPriceWithBenefitsFromState } from '../../state/ducks/ItemCategoriesData/selectors';

function CartSummary() {
  const navigate = useNavigate();

  const cartQtyByProduct = useSelector((state) => ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state));
  const categories = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'));
  const totalCartQty = useSelector((state) => ItemCategoriesDataSelectors.returnTotalCartCountFromState(state));
  const totalCartPrice = useSelector((state) => ItemCategoriesDataSelectors.returnTotalCartPriceFromState(state));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));

  const haveItemsInTheCart = (totalCartQty > 0);

  // Make sure we have the data needed for this page
  useEffect(() => {
    if (categories.length === 0) {
      navigate('/item-categories', { replace: true });
    }
  }, [categories, navigate]);

  // Get our details on benefits coverage
  const totalCatOverBenefit = useMemo(() => {
    let totalOverBenefit = 0;

    Object.entries(categories).forEach(([, group]) => {
      const { products, WICCAT } = group;

      // TODO: Does not take into account the order of products so numbers might not match
      const { catOverBenefit } = calculateBenefitCoverageForCategory(Object.entries(products), benefitsByCategory[`cat-${WICCAT}`], cartQtyByProduct);

      totalOverBenefit += catOverBenefit;
    });

    return totalOverBenefit;
  }, [categories, benefitsByCategory, cartQtyByProduct]);

  return (
    <div className="CartSummary">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          {haveItemsInTheCart ? (
            <>
              <CartCategoriesList categories={categories} />
              <h4 className="m-b-1 text-right">Total: <span className="text-primary">${totalCartPrice.toFixed(2)}</span></h4>
            </>
          ) : (
            <div className="m-b-1">You have not added any items to your cart yet.</div>
          )}
          {totalCatOverBenefit > 0 && (
            <Alert color="danger">You benefits do not cover all the items in your cart. Please go back and remove any items that exceed your benefit coverage.</Alert>
          )}
          {haveItemsInTheCart && totalCatOverBenefit === 0 && (
          <Button block color="primary" onClick={() => navigate('/order-info')}>
            <div className="icon">
              <i className="fas fa-check" />
            </div>
            Checkout
          </Button>
          )}
          <CancelButton className="m-t-2" />
        </div>
      </div>
    </div>
  );
}

export default CartSummary;
