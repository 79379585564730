import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'reactstrap';

import './MainNavbar.scss';
import BackButton from '../BackButton/BackButton';
import ProfileArea from '../ProfileArea/ProfileArea';

function MainNavbar(props) {
  const { showBackButton } = props;
  const isMember = localStorage.getItem('session') === 'member';

  return (
    <Navbar color="dark" dark expand="md" className="MainNavbar">
      {showBackButton && (
        <BackButton />
      )}
      <div className="brand-centered">
        <NavbarBrand href={isMember ? '/stores' : '/'}>
          <img src="/img/app-logo.png" alt="Prime Time Nutrition" />
        </NavbarBrand>
      </div>
      {isMember && (
        <ProfileArea />
      )}
    </Navbar>
  );
}

MainNavbar.propTypes = {
  showBackButton: PropTypes.bool,
};

MainNavbar.defaultProps = {
  showBackButton: true,
};

export default MainNavbar;
