import * as types from './types';

import { actions } from '../../utils';
import { returnItemFromState } from './selectors';
import { BASE_API_URL } from '../../../constants';

const superagent = require('superagent');

// Create our shorthand action dispatches
export const receiveRequest = (scope, data) => actions.returnDispatchSetRequest(types.RECEIVE_STORES_REQUEST, scope, data);
export const setFetching = (scope, fetching) => actions.returnDispatchSetFetching(types.SET_STORES_FETCHING, scope, fetching);
export const setLoading = (scope, loading) => actions.returnDispatchSetLoading(types.SET_STORES_LOADING, scope, loading);
export const toggleBoolean = (scope, toggleDirection) => actions.returnDispatchSetBoolean(types.TOGGLE_STORES_BOOLEAN, scope, toggleDirection);

// Define any functions we need to run our dispatches
/**
 * Load all stores from the API.
 *
 * @returns {Promise<*|*[]>}
 */
async function loadStores() {
  const res = await superagent.get(`${BASE_API_URL}stores?store=417`)
    .set('Accept', 'application/json');

  return res.body.result || [];
}

/**
 * Load all stores from the API that match the query and are in range.
 *
 * @param lat
 * @param lng
 * @param searchQuery
 * @param range
 * @returns {Promise<*|*[]>}
 */
async function loadStoresByLocation(lat, lng, searchQuery, range) {
  const res = await superagent.get(`${BASE_API_URL}stores?store=417&lat=${lat}&lng=${lng}&zipCode=${searchQuery}&range=${range}`)
    .set('Accept', 'application/json');

  return res.body.result || [];
}

// Now create our actions which require us to dispatch back a fetch
/**
 *  Fetch all of our initial data
 *
 * @param lat
 * @param lng
 * @param searchQuery
 * @param range
 * @returns {Function}
 */
export const fetchStores = (lat, lng, searchQuery, range) => (dispatch) => {
  // Mark that we are fetching things
  dispatch(setFetching('stores', true));
  dispatch(receiveRequest('lastQuery', {
    lat,
    lng,
    range,
    searchQuery,
  }));

  let results = null;

  if (lat == null || lng == null) {
    results = loadStores();
  } else {
    results = loadStoresByLocation(lat, lng, searchQuery, range);
  }

  return results.then((response) => {
    const stores = response || [];
    dispatch(receiveRequest('stores', stores));

    // Mark that we are finished fetching things
    dispatch(setFetching('stores', false));
  });
};

/**
 * Parse a stores times and return them in an easy to digest manner.
 *
 * @param store
 * @returns {object}
 */
const parseStartAndEndDates = (store) => {
  const selectedStoreTimesByDoW = {};

  if (store.length === 0) {
    return selectedStoreTimesByDoW;
  }

  const { start_pickup, end_pickup } = store || {};

  const pickupTimesStart = JSON.parse(start_pickup);
  const pickupTimesEnd = JSON.parse(end_pickup);

  for (const [dow, startTime] of Object.entries(pickupTimesStart)) {
    if (pickupTimesEnd[dow] != null) {
      const endTime = pickupTimesEnd[dow];

      selectedStoreTimesByDoW[dow] = {
        end: endTime,
        endHour: (endTime === 'CLSD') ? 0 : parseInt(endTime.substring(0, 2)),
        endMinute: (endTime === 'CLSD') ? 0 : parseInt(endTime.substring(2, endTime.length)),
        start: startTime,
        startHour: (startTime === 'CLSD') ? 0 : parseInt(startTime.substring(0, 2)),
        startMinute: (startTime === 'CLSD') ? 0 : parseInt(startTime.substring(2, startTime.length)),
      };
    }
  }

  // Build our stored version
  return selectedStoreTimesByDoW;
};

/**
 * Determines whether we need to fetch data from a section
 *
 * @param state
 * @param lat
 * @param lng
 * @param searchQuery
 * @param range
 * @returns {boolean}
 */
function shouldFetchStores(state, lat, lng, searchQuery, range) {
  const data = returnItemFromState(state, 'stores');
  const lastQuery = returnItemFromState(state, 'lastQuery');

  // Did our search form change?
  if (lastQuery.searchQuery !== searchQuery || lastQuery.range !== range) {
    return true;
  }

  // Did our position change?
  if (lastQuery.lat !== lat || lastQuery.lng !== lng) {
    // Make sure that location didn't just suddenly disappear
    return !(lat == null || lng == null);
  }
  // do we have data?
  return (!data);
}

/**
 *  Fetch all of our initial data
 *
 * @param lat
 * @param lng
 * @param searchQuery
 * @param range
 * @returns {Function}
 */
export const fetchStoresIfNeeded = (lat, lng, searchQuery, range) => (dispatch, getState) => {
  if (shouldFetchStores(getState(), lat, lng, searchQuery, range)) {
    dispatch(fetchStores(lat, lng, searchQuery, range));
  }
};

/**
 *  Select a store and parse the data for it.
 *
 * @param store
 * @returns {Function}
 */
export const selectStore = (store) => (dispatch) => {
  dispatch(receiveRequest('selectedStore', store));
  dispatch(receiveRequest('selectedStoreTimesByDoW', parseStartAndEndDates(store)));
};
