import { parseCommaFloat } from './helpers';

export const calculateBenefitsByCategory = (cartProducts, benefits) => {
  if (benefits == null || benefits[0].foodBenefitBalance == null) {
    return {};
  }

  const benefitsByCategory = benefits[0].foodBenefitBalance.reduce((acc, benefit) => {
    const { benefitQuantityAvailable, categoryCode, subcategoryCode } = benefit;
    const catKey = `cat-${categoryCode}`;
    const subCatKey = `sub-${subcategoryCode}`;

    if (acc[catKey] == null) {
      acc[catKey] = {};
    }

    acc[catKey][subCatKey] = {
      available: benefitQuantityAvailable,
      used: 0,
      remaining: benefitQuantityAvailable,
    };

    return acc;
  }, {});

  cartProducts.forEach((item) => {
    const { cart_quantity, catid, subid } = item;

    benefitsByCategory[`cat-${catid}`][`sub-${subid}`].used = cart_quantity;
    benefitsByCategory[`cat-${catid}`][`sub-${subid}`].remaining = benefitsByCategory[`cat-${catid}`][`sub-${subid}`].available - cart_quantity;
  });

  return benefitsByCategory;
};

export const calculateBenefitCoverageForCategory = (productsInOrder, benefitsBySubCategory, cartQtyByProduct) => {
  let catCartBftQty = 0;
  let catCartPrice = 0;
  let catCartQty = 0;
  let catBenefitsCovered = 0;
  let catOverBenefit = 0;
  const remainingBenefitsBySubCategory = Object.keys(benefitsBySubCategory || {}).reduce((acc, subIdKey) => {
    acc[subIdKey] = benefitsBySubCategory[subIdKey].available;

    return acc;
  }, {});

  productsInOrder.forEach(([_, item]) => {
    const { prodkey, subid } = item;
    const benefitsAvailable = remainingBenefitsBySubCategory[`sub-${subid}`] || 0;
    const bftQty = parseCommaFloat(item.bftqty);
    const cartQty = parseInt(cartQtyByProduct[prodkey]) || 0;
    const itemPrice = parseCommaFloat(item.price);

    if (cartQty <= 0) {
      return;
    }

    const qtyCoveredByBenefit = Math.min(benefitsAvailable, cartQty);

    catCartBftQty += cartQty * bftQty;
    catCartPrice += cartQty * itemPrice;
    catCartQty += cartQty;
    catBenefitsCovered += qtyCoveredByBenefit * bftQty;
    catOverBenefit += (cartQty - qtyCoveredByBenefit) * itemPrice;

    remainingBenefitsBySubCategory[`sub-${subid}`] -= qtyCoveredByBenefit;
  });

  return {
    catCartBftQty,
    catCartPrice,
    catCartQty,
    catBenefitsCovered,
    catOverBenefit,
  };
};
