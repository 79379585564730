import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import './CartCategory.scss';
import CartProduct from '../CartProduct/CartProduct';
import CartQty from '../CartQty/CartQty';
import { WumeiDataSelectors } from '../../state/ducks/WumeiData';
import { ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { calculateBenefitCoverageForCategory, parseCommaFloat, sortByInCartThenSeq } from '../../utils';

function CartCategory(props) {
  const { description, imageUrl, products, uom, catId } = props;
  const cartQtyByProduct = useSelector((state) => ItemCategoriesDataSelectors.returnCartQtyByProductFromState(state));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));
  const items = [];

  const benefitsAvailableForCat = Object.values(benefitsByCategory[`cat-${catId}`] || {}).reduce((acc, benefit) => acc + benefit.available, 0);

  // Put our products in order based on sequence or ordered amount
  const productsInOrder = Object.entries(products).sort(sortByInCartThenSeq(cartQtyByProduct));
  const { catCartBftQty, catCartPrice, catCartQty, catBenefitsCovered, catOverBenefit } = calculateBenefitCoverageForCategory(productsInOrder, benefitsByCategory[`cat-${catId}`], cartQtyByProduct);

  if (productsInOrder.length > 0) {
    productsInOrder.forEach(([, item]) => {
      const { descript: itemDescription, image_url: itemImageURL, prodkey, subid } = item;
      const benefitsAvailable = benefitsByCategory[`cat-${catId}`]?.[`sub-${subid}`]?.available || 0;
      const bftqty = parseCommaFloat(item.bftqty);
      const cartQty = parseInt(cartQtyByProduct[prodkey]) || 0;
      const itemPrice = parseCommaFloat(item.price);
      const cartPrice = cartQty * itemPrice;

      // TODO: Needs to account for all products in the subcategory
      // This means we need to calculate the benefitCovered outside of this and pass it in
      // We can modify the calculateBenefitCoverageForCategory function to get this data back so its consistent
      const itemAmount = cartQty * bftqty;
      const benefitCovered = benefitsAvailable * bftqty <= itemAmount ? benefitsAvailable * bftqty : itemAmount;
      const overLimit = itemAmount - benefitCovered;

      if (cartQty > 0) {
        items.push(
          <CartProduct
            key={prodkey}
            benefitCovered={benefitCovered}
            cartPrice={cartPrice}
            cartQty={cartQty}
            catUOM={uom}
            description={itemDescription}
            imageUrl={itemImageURL || imageUrl}
            overLimit={overLimit}
            className="item item-block item-md"
          />,
        );
      }
    });
  }

  // If there are no items for this category then we don't show it
  if (catCartQty === 0) {
    return;
  }

  return (
    <div role="link" className="CartCategory">
      <div className="divider">
        <div className="row align-items-center">
          <div className="col-9 col-sm-7 col-md-6 col-lg-7 description">
            <span className="d-block text-truncate">
              {description}
            </span>
          </div>
          <CartQty
            benefitsAvailable={benefitsAvailableForCat}
            cartBftQty={catCartBftQty}
            cartQty={catCartQty}
            className="col-3 col-sm-5 col-md-6 col-lg-5 ms-auto text-right"
            covered={catBenefitsCovered}
            overPrice={catOverBenefit}
            price={catCartPrice}
            uom={uom}
          />
        </div>
      </div>
      <div className="list sub-list">
        {items}
      </div>
    </div>
  );
}

CartCategory.propTypes = {
  catId: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  uom: PropTypes.string,
};

CartCategory.defaultProps = {
  catId: null,
  description: null,
  imageUrl: null,
  products: [],
  uom: null,
};

export default CartCategory;
