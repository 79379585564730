import * as types from './types';

import { actions } from '../../utils';
import { BASE_API_URL } from '../../../constants';

const superagent = require('superagent');

// Create our shorthand action dispatches
export const receiveRequest = (scope, data) => actions.returnDispatchSetRequest(types.RECEIVE_ORDERS_REQUEST, scope, data);
export const setFetching = (scope, fetching) => actions.returnDispatchSetFetching(types.SET_ORDERS_FETCHING, scope, fetching);
export const setLoading = (scope, loading) => actions.returnDispatchSetLoading(types.SET_ORDERS_LOADING, scope, loading);
export const toggleBoolean = (scope, toggleDirection) => actions.returnDispatchSetBoolean(types.TOGGLE_ORDERS_BOOLEAN, scope, toggleDirection);

// Use our shorthand for some basic actions
export const selectOrder = (order) => receiveRequest('selectedOrder', order);

// Define any functions we need to run our dispatches
/**
 * Load all orders from the API that match the query and are in range.
 *
 * @param orderDetails
 * @returns {Promise<*|*[]>}
 */
async function submitOrder(orderDetails) {
  const res = await superagent.post(`${BASE_API_URL}orders`).withCredentials().set('Accept', 'application/json').send(orderDetails);

  return res.body.result || [];
}

// Now create our actions which require us to dispatch back a fetch
/**
 *  Fetch all of our initial data
 *
 * @param orderDetails
 * @returns {Function}
 */
export const sendOrder = (orderDetails) => (dispatch) => {
  // Mark that we are fetching things
  dispatch(setFetching('order', true));

  return submitOrder(orderDetails).then((response) => {
    dispatch(receiveRequest('order', orderDetails));

    // Mark that we are finished fetching things
    dispatch(setFetching('order', false));

    return response;
  });
};
