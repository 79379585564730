import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import './StoresMap.scss';

function StoresMap(props) {
  const { children, defaultCenter, defaultZoom, onClickMarker, stores } = props;
  const containerStyle = {
    width: '100%',
    height: '400px',
  };
  const safeDefaultCenter = (defaultCenter.lat == null || defaultCenter.lng == null) ? null : { lat: +defaultCenter.lat, lng: +defaultCenter.lng };

  const markers = [];
  Object.entries(stores).forEach(([index, store]) => {
    markers.push(
      <Marker
        key={store.uuid}
        position={{ lat: parseFloat(store.lat), lng: parseFloat(store.lng) }}
        onClick={() => onClickMarker(index)}
      />,
    );
  });

  return (
    <div className="StoresMap">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={safeDefaultCenter || { lat: 34.074072, lng: -118.040627 }}
        zoom={defaultZoom}
      >
        {safeDefaultCenter && (
          <Marker
            position={safeDefaultCenter}
            icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
          />
        )}
        {markers}
        {children}
      </GoogleMap>
    </div>
  );
}

StoresMap.propTypes = {
  defaultCenter: PropTypes.object,
  defaultZoom: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClickMarker: PropTypes.func,
  stores: PropTypes.arrayOf(PropTypes.object),
};

StoresMap.defaultProps = {
  defaultCenter: { lat: null, lng: null },
  defaultZoom: 8,
  children: null,
  onClickMarker: null,
  stores: [],
};

export default StoresMap;
