import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import './CartProduct.scss';
import classNames from 'classnames';

function CartProduct(props) {
  const { benefitCovered, cartPrice, cartQty, catUOM, description, imageUrl, overLimit } = props;

  return (
    <FormGroup className="CartProduct row mx-0">
      <div className="thumbnail col-3 col-sm-2 text-center">
        <img src={imageUrl} alt="" />
      </div>
      <div className="description col-6 col-sm-6 col-md-5 col-lg-6">
        <span className="d-block text-truncate">
          {description}
        </span>
      </div>
      <div className="col-3 col-sm-4 col-md-5 col-lg-4 ms-auto text-right">
        <span className={classNames('d-none', 'd-sm-inline')}>Qty: </span>
        <span className={classNames({ 'text-success': benefitCovered, 'text-danger': !benefitCovered })}>
          {cartQty}
        </span>
        <span className={classNames('d-none', 'd-sm-inline')}> | </span>
        <span className={classNames('d-none', 'd-md-inline')}>Total: </span>
        <span className={classNames('d-none', 'd-sm-inline', { 'text-success': benefitCovered, 'text-danger': !benefitCovered })}>
          ${cartPrice.toFixed(2)}
        </span>

        {overLimit > 0 && (
          <>
            <span className={classNames('d-none', 'd-md-inline')}> | </span>

            <span className={classNames('d-none', 'd-md-inline')}>Over limit: </span>
            <span className={classNames({ 'text-success': !overLimit, 'text-danger': overLimit })}>
              {overLimit} {catUOM}
            </span>
          </>
        )}
      </div>
    </FormGroup>
  );
}

CartProduct.propTypes = {
  benefitCovered: PropTypes.number,
  cartPrice: PropTypes.any,
  cartQty: PropTypes.number,
  catUOM: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  overLimit: PropTypes.number,
};

CartProduct.defaultProps = {
  benefitCovered: 0,
  cartPrice: 0,
  cartQty: 0,
  catUOM: null,
  description: null,
  imageUrl: null,
  overLimit: 0,
};

export default CartProduct;
