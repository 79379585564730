import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import './CancelButton.scss';
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

function CancelButton(props) {
  const { className } = props;
  const navigate = useNavigate();

  const showSwal = () => {
    MySwal.fire({
      title: 'Cancel Order',
      icon: 'error',
      text: 'Are you sure you want to cancel your order?',
      showCancelButton: true,
      confirmButtonText: 'Yes, Cancel It',
      cancelButtonText: 'No, Keep Working',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate('/', { replace: true });
      }
    });
  };

  return (
    <Button color="danger" className={classNames('cancelButton', className)} onClick={showSwal}>
      <div className="icon">
        <i className="fas fa-times" />
      </div>
      Cancel
    </Button>
  );
}

CancelButton.defaultProps = {
  className: '',
};

CancelButton.propTypes = {
  className: PropTypes.string,
};

export default CancelButton;
