import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, FormGroup, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './ItemDetails.scss';

import MainNavbar from '../../components/MainNavbar/MainNavbar';
import { useInput } from '../../hooks/useInput';
import { parseCommaFloat } from '../../utils';
import { WumeiDataOperations, WumeiDataSelectors } from '../../state/ducks/WumeiData';
import { ItemCategoriesDataOperations, ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';

const MySwal = withReactContent(Swal);

function ItemDetails() {
  const dispatch = useDispatch();

  const categories = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'));
  const selectedProducts = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'selectedProducts'));
  const benefitsByCategory = useSelector((state) => WumeiDataSelectors.returnItemFromState(state, 'benefitsByCategory'));

  const selectProducts = (product) => dispatch(ItemCategoriesDataOperations.selectProducts(product));
  const updateBenefitsByCategory = (cartProducts) => dispatch(WumeiDataOperations.updateBenefitsByCategory(cartProducts));

  const { catId, productId } = useParams();
  const [selectedItem, setSelectedItem] = useState({});
  const [benefitBalance, setBenefitBalance] = useState(0);
  const { value: quantity, bind: bindQuantity, setValue: setQuantity } = useInput(1);
  const navigate = useNavigate();

  const { image_url, descript = 'Item Name', price } = selectedItem;
  const total = (parseCommaFloat(price) * quantity).toFixed(2);

  useEffect(() => {
    if (categories.length === 0) {
      navigate('/stores', { replace: true });
    }
  }, [categories, navigate]);

  useEffect(() => {
    // See if the product is in the selectedProducts set
    let productFoundInSelectedProducts = null;

    if (selectedProducts != null && selectedProducts.products != null) {
      const { products = [] } = selectedProducts.products;

      // Look for product already being in the cart
      products.forEach((product) => {
        if (productId.toString() === product.prodkey.toString()) {
          productFoundInSelectedProducts = product;
        }
      });
    }

    if (productFoundInSelectedProducts != null) {
      // We've added this to the cart before so lets access it
      setSelectedItem(productFoundInSelectedProducts);
      setQuantity(parseInt(productFoundInSelectedProducts.cart_quantity));
    } else {
      // We need to find it in the main categories object
      Object.entries(categories).forEach(([, cat]) => {
        const { WICCAT, products } = cat;

        if (catId.toString() === WICCAT.toString()) {
          for (const product of products) {
            const { prodkey } = product;

            if (productId.toString() === prodkey.toString()) {
              setSelectedItem(product);
              setQuantity(1);
            }
          }
        }
      });
    }
  }, [categories, catId, productId, selectedProducts, setQuantity]);

  /**
   * The hook tracks data on the user's benefits, filtering and forming
   * the appropriate balance of the product available for the benefit
   * according to the category and subcategory of it.
   *
   */
  useEffect(() => {
    if (!benefitBalance && Object.keys(selectedItem).length > 0) {
      const benefitsRemaining = benefitsByCategory[`cat-${selectedItem.catid}`]?.[`sub-${selectedItem.subid}`]?.remaining || 0;

      // Now determine how much we could change here
      const balanceWithCurrentProduct = benefitsRemaining + (selectedItem.cart_quantity || 0);

      setBenefitBalance(balanceWithCurrentProduct);
    }
  }, [benefitBalance, benefitsByCategory, selectedItem]);

  const options = [];
  const maxOptionsToAllow = 25;
  const maxInventory = (selectedItem.fiseq != null && selectedItem.fiseq.length > 0) ? Math.min(selectedItem.fiseq, maxOptionsToAllow) : maxOptionsToAllow;
  const maxQty = Math.min(benefitBalance, maxInventory);

  for (let qty = 0; qty <= maxQty; qty += 1) {
    options.push(<option value={qty} key={qty}>{qty}</option>);
  }

  const addItem = () => {
    const alert = {
      allowOutsideClick: false,
      icon: 'error',
    };

    if (quantity < 0) {
      return MySwal.fire({
        ...alert,
        text: 'Please enter a Quantity to add this Product.',
      });
    }

    if (quantity > maxQty) {
      return MySwal.fire({
        ...alert,
        text: `This quantity is not available. The max quantity for this product is ${maxQty}.`,
      });
    }

    const bftQty = parseCommaFloat(selectedItem.bftqty);
    const uiQuantity = quantity * bftQty;

    if (benefitBalance && quantity > benefitBalance) {
      return MySwal.fire({
        ...alert,
        text: `You have a total of ${uiQuantity} ${selectedItem.catUOM} of "${descript}" selected. Your benefits only allow
             for ${benefitBalance * bftQty} ${selectedItem.catUOM}.`,
      });
    }

    selectedItem.cart_quantity = quantity;

    let cart = {
      products: [],
    };
    let products = [];

    if (selectedProducts != null && selectedProducts.products != null) {
      cart = selectedProducts;
      products = selectedProducts.products || [];

      // Look for product already being in the cart
      products.forEach((product, index) => {
        if (product.prodkey === selectedItem.prodkey) {
          products.splice(index, 1);
        }
      });
    }

    // Add the product
    products.push(selectedItem);

    cart.products = products;

    // Update our cart products
    selectProducts(cart);

    // Recalculate the benefits
    updateBenefitsByCategory(cart.products);
    navigate('/item-categories', { replace: true });
  };

  return (
    <div className="ItemDetails">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer">
        <div className="container-fluid">
          <div className="list add-item-list">
            <div className="thumbnail start">
              <img src={image_url} alt="" />
            </div>
            <h3 className="item-name">
              {descript}
            </h3>
            <h3 className="text-primary">
              ${total}
            </h3>

            <FormGroup className="add-item row">
              <Label className="col-sm-2 col-form-label">Quantity:</Label>
              <div className="col-sm-10">
                <select className="form-control" {...bindQuantity}>
                  {options}
                </select>
              </div>
            </FormGroup>
            <Button color="primary" className="add-item-btn" block onClick={addItem}>Update Order</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetails;
