import React, { useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import MainNavbar from '../../components/MainNavbar/MainNavbar';

import './ItemCategories.scss';
import { ItemCategoriesDataOperations, ItemCategoriesDataSelectors } from '../../state/ducks/ItemCategoriesData';
import { StoresDataSelectors } from '../../state/ducks/StoresData';
import ItemCategoriesList from '../../components/ItemCategoriesList/ItemCategoriesList';
import CancelButton from '../../components/CancelButton/CancelButton';

function ItemCategories() {
  const dispatch = useDispatch();

  const categories = useSelector((state) => ItemCategoriesDataSelectors.returnItemFromState(state, 'categories'));
  const isReady = useSelector((state) => ItemCategoriesDataSelectors.isReady(state));
  const selectedStore = useSelector((state) => StoresDataSelectors.returnItemFromState(state, 'selectedStore'));
  const totalCartQty = useSelector((state) => ItemCategoriesDataSelectors.returnTotalCartCountFromState(state));

  const haveItemCategories = (categories.length > 0);
  const haveItemsInTheCart = (totalCartQty > 0);
  const navigate = useNavigate();

  // Make sure we have the data needed for this page
  useEffect(() => {
    if (selectedStore == null) {
      navigate('/stores', { replace: true });
    }
  }, [categories, navigate, selectedStore]);

  useEffect(() => {
    const fetchAllCategoriesIfNeeded = (storeNo) => dispatch(ItemCategoriesDataOperations.fetchAllCategoriesIfNeeded(storeNo));

    if (selectedStore) {
      fetchAllCategoriesIfNeeded(selectedStore.store_no);
    }
  }, [dispatch, selectedStore]);

  const goToItemDetails = (catId, productId) => {
    navigate(`/item-details/${catId}/${productId}`);
  };

  return (
    <div className="ItemCategories">
      <header className="bg-dark fixed-top"><MainNavbar /></header>
      <div className="safeAreaWrapper fixed-top-spacer footer-spacer">
        <div className="container-fluid">
          {isReady ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {haveItemCategories ? (
                <>
                  <p>Please select items for your order that are available from your {selectedStore.state} WIC Benefits</p>
                  <ItemCategoriesList categories={categories} onProductClick={goToItemDetails} />
                </>
              ) : (
                <div className="m-t-2">No categories to purchase from were found for your store.</div>
              )}
            </>
          ) : (
            <span className="loadingSpinner"><i className="fas fa-spinner fa-spin" /></span>
          )}
        </div>
        <footer>
          <div className="toolbar container-fluid">
            <Row className="gx-1">
              <Col xs="4">
                <CancelButton />
              </Col>
              <Col xs="8" className="text-right">
                {haveItemsInTheCart && (
                  <Button color="primary" onClick={() => navigate('/cart-summary')}>
                    <div className="icon">
                      <i className="fas fa-check" />
                    </div>
                    Done with Products
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ItemCategories;
