/**
 * Parses strings as floating-point numbers, processing strings
 * where both a dot and a comma can be used as a separator.
 *
 * @param str string
 *
 * @returns {number}
 */
export function parseCommaFloat(str) {
  return parseFloat(String(str).replace(/(?<!.*\..*),(?=[^,]*$)/g, '.'));
}
