/**
 * Return the auth controls section of the state.
 *
 * @param state
 */
export function returnMySliceOfState(state) {
  return state.AuthData;
}

/**
 * Marks the status of the authorization request loading.
 *
 * @param state
 * @returns boolean
 */
export function isLoading(state) {
  const curState = returnMySliceOfState(state);

  return (curState.auth_isLoading);
}

/**
 * Check if we are ready to display.
 *
 * @param state
 * @returns boolean
 */
export function isReady(state) {
  return !isLoading(state);
}

/**
 * Checks whether additional data is needed to complete authorization.
 *
 * @param state
 * @returns {*}
 */
export function userData(state) {
  return returnMySliceOfState(state)?.userData;
}
