/**
 * Return the WUMEI controls section of the state.
 *
 * @param state
 */
export function returnMySliceOfState(state) {
  return state.WumeiData;
}

/**
 * Check if we are fetching any data from the WUMEI API.
 *
 * @param state
 * @returns boolean
 */
export function isFetching(state) {
  const curState = returnMySliceOfState(state);

  return curState.wumei_isFetching;
}

/**
 * Check if we are ready to display.
 *
 * @param state
 * @returns boolean
 */
export function isReady(state) {
  return !isFetching(state);
}

/**
 * Return an item from our state object.
 *
 * @param state
 * @param item
 * @returns {*}
 */
export function returnItemFromState(state, item) {
  return returnMySliceOfState(state)[item];
}
