/**
 * Create a reducer from a map of reducer creators
 *
 * @param initialState
 * @returns {function(*): function(*=, *=): *}
 */
// eslint-disable-next-line default-param-last
export const createReducer = (initialState) => (reducerMap) => (state = initialState, action) => {
  const reducer = reducerMap[action.type];
  return reducer ? reducer(state, action) : state;
};
