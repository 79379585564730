import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import './Product.scss';
import classNames from 'classnames';

function Product(props) {
  const { benefitsAvailable, cartQuantity, description, imageUrl, onProductClick } = props;
  const itemAmount = cartQuantity || 0;
  const hasBenefits = benefitsAvailable > 0;
  const overLimit = itemAmount > benefitsAvailable;

  return (
    <FormGroup onClick={onProductClick} className="Product row mx-0">
      <div className="thumbnail col-3 col-sm-2 text-center">
        <img src={imageUrl} alt="" />
      </div>
      <div className="description col-6 col-sm-6 col-md-5 col-lg-6">
        <span className="d-block text-truncate">
          {description}
        </span>
      </div>
      <div className="col-3 col-sm-4 col-md-5 col-lg-4 ms-auto text-right">
        <span className={classNames('d-none', 'd-md-inline')}>Benefits: </span>
        <span className={classNames('d-none', 'd-md-inline', { 'text-success': hasBenefits, 'text-danger': !hasBenefits })}>
          {benefitsAvailable}
        </span>
        <span className={classNames('d-none', 'd-md-inline')}> | </span>

        <span className={classNames('d-none', 'd-sm-inline')}>Selected: </span>
        <span className={classNames({ 'text-success': !overLimit, 'text-danger': overLimit })}>
          {itemAmount}
        </span>

        <div className="icon">
          <i className="fas fa-caret-right" />
        </div>
      </div>
    </FormGroup>
  );
}

Product.propTypes = {
  benefitsAvailable: PropTypes.number,
  cartQuantity: PropTypes.any,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  onProductClick: PropTypes.func,
};

Product.defaultProps = {
  benefitsAvailable: null,
  cartQuantity: null,
  description: null,
  imageUrl: null,
  onProductClick: null,
};

export default Product;
