import * as types from './types';

import { createReducer, reducers } from '../../utils';

const initialState = {
  userData: null,
  auth_isLoading: false,
};

const analyticsInsightLabelsItemReducer = createReducer(initialState)({
  [types.RECEIVE_AUTH_REQUEST]: (state, action) => reducers.requestReducer(state, action),
  [types.SET_AUTH_LOADING]: (state, action) => reducers.loadingReducer(state, action),
});

export default analyticsInsightLabelsItemReducer;
