const authagent = require('superagent-use')(require('superagent'));

/**
 * Initialize the use of the headers required to make authorized
 * requests for each associated request.
 *
 */
authagent.use(async (req) => {
  req.header = {
    ...req.header,
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Accept, X-Requested-With, Content-Type, Authorization',
    'Access-Control-Allow-Origin': 'https://devorders.nfiptn.com',
  };

  return req;
});

export { authagent };
