import React from 'react';
import { useNavigate } from 'react-router-dom';

import './BackButton.scss';

function BackButton() {
  const navigate = useNavigate();

  return (
    <button type="button" className="backButton" onClick={() => navigate(-1)}>
      <i className="fas fa-arrow-left" />
    </button>
  );
}

BackButton.propTypes = {};

export default BackButton;
